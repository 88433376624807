import React from 'react';
import './IconSection.css';
import AiIcon from '../../../assets/Ai-tag.png';
import ReUploadIcon from '../../../assets/Reupload-tag.png';
import SecurityIcon from '../../../assets/Security-tag.png';
import PersonalIcon from '../../../assets/Personal-tag.png';
import BusinessIcon from '../../../assets/Business-tag.png';

const IconSection = () => {
    return(
        <div className="iconSection-main-container">
            <div className="iconSection-top-container">
                <div className="icon-container-special">
                    <div className="icon-title">
                        <h3>Local AI Experts</h3>
                    </div>
                    <div className="icon-text">
                        <p>Helix Labs delivers AI solutions with a personal touch. Based in Oklahoma City, we combine local presence with cutting-edge AI expertise.</p>
                    </div>
                </div>
                <div className="icon-container-normal">
                    <div className="icon-icon">
                    <img src={AiIcon} alt="top-icon" className="top-icon" />
                    </div>
                    <div className="icon-title">
                        <h3>AI Advisory</h3>
                    </div>
                    <div className="icon-text">
                        <p>We help you navigate the AI landscape with strategic guidance on technology selection, implementation roadmaps, and ethical considerations.</p>
                    </div>
                </div>
                <div className="icon-container-normal">
                    <div className="icon-icon">
                    <img src={ReUploadIcon} alt="top-icon" className="top-icon-reupload" />
                    </div>
                    <div className="icon-title">
                        <h3>AI Consulting</h3>
                    </div>
                    <div className="icon-text">
                        <p>Our hands-on consulting services include AI readiness assessments, implementation planning, and practical training to help your team leverage AI effectively.</p>
                    </div>
                </div>
                <div className="icon-container-normal">
                    <div className="icon-icon">
                    <img src={SecurityIcon} alt="top-icon" className="top-icon-security" />
                    </div>
                    <div className="icon-title">
                        <h3>Secure Solutions</h3>
                    </div>
                    <div className="icon-text">
                        <p>We prioritize data security in all our AI implementations, offering on-premise solutions that keep your sensitive information under your control.</p>
                    </div>
                </div>
            </div>
            <div className="icon-bottom-container">
                <div className="icon-bottom-container-left">
                 <div className="icon-icon-bottom">
                 <img src={PersonalIcon} alt="top-icon" className="bottom-icon" />
                    </div>
                    <div className="icon-title-bottom">
                        <h2>Custom AI Development</h2>
                    </div>
                    <div className="icon-text-bottom">
                        <p>We build tailored AI solutions designed specifically for your business needs, from intelligent automation to advanced data analysis systems that deliver actionable insights.</p>
                    </div>
                </div>
                <div className="icon-bottom-container-right">
                <div className="icon-icon-bottom">
                <img src={BusinessIcon} alt="top-icon" className="bottom-icon" />
                    </div>
                    <div className="icon-title-bottom">
                        <h2>Consulting</h2>
                    </div>
                    <div className="icon-text-bottom">
                        <p>We provide expert AI consulting services to help your organization navigate the AI landscape, develop effective strategies, and implement solutions that drive real business outcomes.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IconSection;