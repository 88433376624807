import React, { useState } from 'react';
import axios from 'axios';
import Footer from '../../../Components/Footer/Footer';
import './AISolutions.css';

const CustomAISolutions = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });


// Add new state variables for form submission status
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null); // null, 'success', or 'error'
  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // Clear any error messages when user starts typing again
    if (submitStatus === 'error') {
      setSubmitStatus(null);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/contact`, formData);
      setSubmitStatus('success');
      setStatusMessage(response.data.message || 'Your message has been sent successfully. We\'ll be in touch soon!');
      setFormData({ name: '', email: '', message: '' });

      // Automatically clear success message after 8 seconds
      setTimeout(() => {
        setSubmitStatus(null);
      }, 8000);

    } catch (error) {
      setSubmitStatus('error');
      setStatusMessage(error.response?.data?.message || 'An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
const renderContactForm = () => {
    return (
      <form className="contact-form" onSubmit={handleSubmit}>
        {/* Status message notification */}
        {submitStatus && (
          <div className={`form-notification ${submitStatus}-notification`}>
            <div className="notification-icon">
              {submitStatus === 'success' ? '✓' : '⚠'}
            </div>
            <div className="notification-message">{statusMessage}</div>
            <button
              type="button"
              className="notification-close"
              onClick={() => setSubmitStatus(null)}
            >
              ×
            </button>
          </div>
        )}

        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Your Name"
          required
          disabled={isSubmitting}
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Your Email"
          required
          disabled={isSubmitting}
        />
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Tell us about your AI ambitions"
          required
          disabled={isSubmitting}
        ></textarea>
        <button
          type="submit"
          className={`cta-button ${isSubmitting ? 'submitting' : ''}`}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'SENDING...' : 'START THE CONVERSATION'}
        </button>
      </form>
    );
  };


  return (
    <div className="ai-solutions-page">
      {/* Network background effect retained */}
      <div className="network-bg"></div>

      <main>
        {/* AI Solutions Banner Section */}
        <section className="aiSolutionsBanner">
          <div className="aiSolutionsBanner-wrapper">
            <div className="aiSolutionsBanner-content">
              <h1>
                <span className="title-impact">AI Solutions</span>
                <span className="title-focus">Built By Practitioners</span>
              </h1>
              <div className="aiSolutionsBanner-divider"></div>
              <p className="aiSolutionsBanner-tagline">We develop, train, and implement AI solutions that deliver real results</p>
              <a href="#contact" className="cta-button">DISCUSS YOUR PROJECT</a>
            </div>
            <div className="aiSolutionsBanner-graphic">
              <div className="code-graphic">
                <div className="code-line"></div>
                <div className="code-line"></div>
                <div className="code-line"></div>
                <div className="code-line"></div>
                <div className="code-line"></div>
                <div className="code-line"></div>
                <div className="code-line"></div>
                <div className="code-line"></div>
                 <div className="code-line"></div>
              </div>
            </div>
          </div>
        </section>

        {/* Our AI Approach Section */}
        <section className="aiSolutionsApproach">
          <div className="container">
            <div className="aiSolutionsApproach-header">
              <h2>Not Your Typical AI Company</h2>
              <div className="aiSolutionsApproach-underline"></div>
            </div>
            <div className="aiSolutionsApproach-content">
              <div className="aiSolutionsApproach-text">
                <p className="aiSolutionsApproach-intro">
                  Unlike consultants who merely talk about AI's potential, we're a team of builders who create and use AI tools every single day.
                </p>

                <div className="aiSolutionsApproach-column">
                  <h3>We're Builders First</h3>
                  <p>We use the same kind of tools we build for clients in our own operations daily. This gives us unmatched insight into what actually works in real business environments, not just theory.</p>
                </div>

                <div className="aiSolutionsApproach-column">
                  <h3>Deep Technical Expertise</h3>
                  <p>Our team includes AI engineers who've trained and fine-tuned models across industries, not just consultants who talk about AI. We bring hands-on experience with the latest AI techniques.</p>
                </div>

                <div className="aiSolutionsApproach-column">
                  <h3>Results-Driven Approach</h3>
                  <p>We measure success in business outcomes, not technical jargon. Every solution is designed to deliver measurable ROI and address specific business challenges.</p>
                </div>
              </div>
              <div className="aiSolutionsApproach-stat">
                <div className="stat-highlight">100%</div>
                <p>of our team use and build AI tools on a daily basis</p>
              </div>
            </div>
          </div>
        </section>

        {/* AI Capabilities Section - UPDATED */}
        <section className="aiSolutionsCapabilities">
          <div className="container">
            <div className="aiSolutionsCapabilities-header"
                style={{ color: "#f0dd5f" }}
                >
            <h2>Our AI Capabilities</h2>
              <div className="aiSolutionsCapabilities-underline"></div>
            </div>

            <div className="capabilities-grid">
              <div className="capability-card">
                <div className="capability-icon">
                  <div className="icon-circle"></div>
                </div>
                <div className="capability-content">
                  <h3>Custom AI Tool Development</h3>
                  <p>We create bespoke AI-powered applications that integrate seamlessly into your existing workflows, automating complex tasks and augmenting your team's capabilities.</p>
                </div>
              </div>

              <div className="capability-card">
                <div className="capability-icon">
                  <div className="icon-circle"></div>
                </div>
                <div className="capability-content">
                  <h3>AI Model Training & Fine-Tuning</h3>
                  <p>We specialize in developing, training, and fine-tuning advanced machine learning models that understand your business context and data, delivering more accurate and relevant results.</p>
                </div>
              </div>

              <div className="capability-card">
                <div className="capability-icon">
                  <div className="icon-circle"></div>
                </div>
                <div className="capability-content">
                  <h3>AI-Powered Software Solutions</h3>
                  <p>From intelligent automation platforms to advanced analytics dashboards, we build end-to-end software solutions enhanced with AI capabilities to transform how you operate.</p>
                </div>
              </div>

              <div className="capability-card">
                <div className="capability-icon">
                  <div className="icon-circle"></div>
                </div>
                <div className="capability-content">
                  <h3>End-to-End Implementation</h3>
                  <p>We handle everything from initial concept to full deployment and ongoing support, ensuring your AI solutions continue to evolve and deliver value as your business grows.</p>
                </div>
              </div>
            </div>

            <div className="aiSolutionsCapabilities-cta">
              <a href="#contact" className="secondary-button">See How We Can Help</a>
            </div>
          </div>
        </section>

        {/* Process Section */}
        <section className="process-section">
          <div className="container">
            <div className="section-header">
              <h2>Our Approach to AI Development</h2>
              <div className="underline"></div>
            </div>
            <div className="process-timeline">
              <div className="process-step">
                <div className="step-number">01</div>
                <div className="step-content">
                  <h3>Discovery & Strategy</h3>
                  <p>We start by deeply understanding your business challenges and opportunities to identify where AI can deliver the most value.</p>
                </div>
              </div>
              <div className="process-step">
                <div className="step-number">02</div>
                <div className="step-content">
                  <h3>Solution Design</h3>
                  <p>Our team designs a tailored AI solution that addresses your specific needs, with clear metrics for success.</p>
                </div>
              </div>
              <div className="process-step">
                <div className="step-number">03</div>
                <div className="step-content">
                  <h3>Development & Training</h3>
                  <p>We build and train your custom AI tools and models using your data and unique business context.</p>
                </div>
              </div>
              <div className="process-step">
                <div className="step-number">04</div>
                <div className="step-content">
                  <h3>Implementation & Integration</h3>
                  <p>We seamlessly integrate AI solutions into your existing systems and workflows, with minimal disruption.</p>
                </div>
              </div>
              <div className="process-step">
                <div className="step-number">05</div>
                <div className="step-content">
                  <h3>Continuous Optimization</h3>
                  <p>We continually refine and improve your AI solutions based on real-world performance and evolving business needs.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Contact Section */}
         <section id="contact" className="contact-section">
          <div className="container">
            <div className="contact-flex">
              <div className="contact-content">
                <h2>Ready to Transform Your Business with AI?</h2>
                <p>Let's discuss how our hands-on expertise can help you build AI solutions that deliver real results.</p>
                <ul className="contact-benefits">
                  <li>Custom solutions built for your specific needs</li>
                  <li>Practical AI that integrates with your existing systems</li>
                  <li>Ongoing support from a team that uses these tools daily</li>
                  <li>Clear focus on business outcomes and ROI</li>
                </ul>
              </div>
              <div className="contact-form-container">
                {renderContactForm()}
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default CustomAISolutions;