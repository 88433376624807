import React, { useRef, useEffect } from 'react';

const NetworkBackground = ({ theme = 'dark' }) => {
  const canvasRef = useRef(null);
  const nodes = useRef([]);
  const animationFrameId = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let width = window.innerWidth;
    let height = window.innerHeight;
    canvas.width = width;
    canvas.height = height;

    // Create nodes based on the canvas area (more nodes on larger screens)
    const createNodes = () => {
      nodes.current = [];
      const count = Math.floor((width * height) / 12000); // adjust density here
      for (let i = 0; i < count; i++) {
        nodes.current.push({
          x: Math.random() * width,
          y: Math.random() * height,
          vx: (Math.random() - 0.5) * .5,
          vy: (Math.random() - 0.5) * .5,
          radius: Math.random() * 3 + 1,
        });
      }
    };

    createNodes();

    // Animation loop: update positions, draw nodes and connecting lines
    const animate = () => {
      ctx.clearRect(0, 0, width, height);

      // Draw and update nodes
      nodes.current.forEach(node => {
        // Update position
        node.x += node.vx;
        node.y += node.vy;

        // Bounce off edges
        if (node.x < 0 || node.x > width) node.vx *= -1;
        if (node.y < 0 || node.y > height) node.vy *= -1;

        // Draw node (circle)
        ctx.beginPath();
        // Use a light color on dark theme, dark color on light theme
        ctx.fillStyle = theme === 'dark'
          ? 'rgba(255, 255, 255, 0.8)'
          : 'rgba(0, 0, 0, 0.8)';
        ctx.arc(node.x, node.y, node.radius, 0, 2 * Math.PI);
        ctx.fill();
      });

      // Draw lines between nodes that are close to each other
      for (let i = 0; i < nodes.current.length; i++) {
        for (let j = i + 1; j < nodes.current.length; j++) {
          const nodeA = nodes.current[i];
          const nodeB = nodes.current[j];
          const dx = nodeA.x - nodeB.x;
          const dy = nodeA.y - nodeB.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          const maxDistance = 150; // adjust the threshold for connections

          if (distance < maxDistance) {
            const opacity = 1 - distance / maxDistance;
            ctx.beginPath();
            ctx.strokeStyle = theme === 'dark'
              ? `rgba(255, 255, 255, ${opacity * 0.2})`
              : `rgba(0, 0, 0, ${opacity * 0.3})`;
            ctx.lineWidth = 2;
            ctx.moveTo(nodeA.x, nodeA.y);
            ctx.lineTo(nodeB.x, nodeB.y);
            ctx.stroke();
          }
        }
      }

      animationFrameId.current = requestAnimationFrame(animate);
    };

    animate();

    // Recreate nodes and update canvas dimensions on resize
    const handleResize = () => {
      width = window.innerWidth;
      height = window.innerHeight;
      canvas.width = width;
      canvas.height = height;
      createNodes();
    };
    window.addEventListener('resize', handleResize);

    // Cleanup when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationFrameId.current);
    };
  }, [theme]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
        pointerEvents: 'none',
      }}
    />
  );
};

export default NetworkBackground;
