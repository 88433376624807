import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setLoginStatus } from '../../redux/actions/authActions'; // Adjust path as needed
import './Navbar.css';

/**
 * Navbar Component
 *
 * Features:
 * 1. Displays "Helix Labs" in a gradient text.
 * 2. Desktop navigation includes:
 *    - Home
 *    - What We Do (dropdown)
 *    - Blog
 *    - Login  (dispatches setLoginStatus(true), goes to /auth)
 *    - Get Started (green box, dispatches setLoginStatus(false), goes to /auth)
 * 3. Mobile hamburger menu for smaller screens with the same links.
 * 4. Clicking outside any dropdown (What We Do or mobile) closes it.
 */

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Track whether the mobile menu is open
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Track whether the "What We Do" desktop dropdown is open
  const [isWhatWeDoOpen, setIsWhatWeDoOpen] = useState(false);

  // Track whether the mobile "What We Do" dropdown is open
  const [isWhatWeDoMobileOpen, setIsWhatWeDoMobileOpen] = useState(false);

  // References for detecting outside clicks
  const mobileMenuRef = useRef(null);
  const whatWeDoRef = useRef(null);

  /**
   * Toggle the mobile menu
   */
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
    // Close the what we do dropdown when closing mobile menu
    if (isMobileMenuOpen) {
      setIsWhatWeDoMobileOpen(false);
    }
  };

  /**
   * Toggle the "What We Do" dropdown (desktop)
   */
  const toggleWhatWeDoDropdown = () => {
    setIsWhatWeDoOpen((prev) => !prev);
  };

  /**
   * Toggle the "What We Do" dropdown (mobile)
   */
  const toggleWhatWeDoMobileDropdown = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    setIsWhatWeDoMobileOpen((prev) => !prev);
  };

  /**
   * Close open menus if user clicks outside them
   */
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the mobile menu if clicked outside
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target)
      ) {
        setIsMobileMenuOpen(false);
        setIsWhatWeDoMobileOpen(false);
      }
      // Close the "What We Do" dropdown if clicked outside
      if (whatWeDoRef.current && !whatWeDoRef.current.contains(event.target)) {
        setIsWhatWeDoOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  /**
   * Handle the Login link:
   *  - setLoginStatus(true) to show the Login form
   *  - navigate to /auth
   */
  const handleLogin = () => {
    dispatch(setLoginStatus(true));
    navigate('/auth');
  };

  /**
   * Handle the "Get Started" link:
   *  - setLoginStatus(false) to show the Sign Up form
   *  - navigate to /auth
   */
  const handleGetStarted = () => {
    dispatch(setLoginStatus(false));
    navigate('/auth');
  };

  return (
    <div className="top-bar">
      {/* Brand Title with gradient */}
      <div className="title gradient__text">Helix Labs</div>

      {/* ========================== DESKTOP NAV ========================== */}
      <div className="navbar-links desktop-nav">
        <Link to="/">Home</Link>

        {/* "What We Do" dropdown (desktop) */}
        <div className="what-we-do-dropdown" ref={whatWeDoRef}>
          <span onClick={toggleWhatWeDoDropdown}>What We Do</span>
          {isWhatWeDoOpen && (
            <div className="what-we-do-dropdown-content">
              <Link to="/custom-ai-solutions">Custom AI Solutions</Link>
              <Link to="/ai-consulting">AI Consulting Services</Link>
              <Link to="/data-optimization">Data Optimization</Link>
              <Link to="/tempChat">Chat</Link>
            </div>
          )}
        </div>

        <Link to="/blog">Blog</Link>

        {/* The "Login" link toggles the form to login mode */}
        <span
          onClick={handleLogin}
          style={{ cursor: 'pointer' }}
        >
          Login
        </span>

        {/* The green "Get Started" container */}
        <div className="get-started-container">
          <span
            onClick={handleGetStarted}
            style={{ cursor: 'pointer' }}
          >
            Get Started
          </span>
        </div>
      </div>

      {/* ========================== MOBILE NAV ========================== */}
      <div className="mobile-nav" ref={mobileMenuRef}>
        <button onClick={toggleMobileMenu} className="hamburger-btn">
          <div className={`hamburger-icon ${isMobileMenuOpen ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>

        {isMobileMenuOpen && (
          <div className="mobile-nav-dropdown">
            <Link to="/" onClick={() => setIsMobileMenuOpen(false)}>
              Home
            </Link>

            {/* Nested "What We Do" in mobile menu */}
            <div className="what-we-do-dropdown-mobile">
              <span
                onClick={toggleWhatWeDoMobileDropdown}
                className={isWhatWeDoMobileOpen ? 'active' : ''}
              >
                What We Do {isWhatWeDoMobileOpen ? '−' : '+'}
              </span>

              {isWhatWeDoMobileOpen && (
                <div className="mobile-submenu">
                  <Link
                    to="/custom-ai-solutions"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Custom AI Solutions
                  </Link>
                  <Link
                    to="/ai-consulting"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    AI Consulting Services
                  </Link>
                  <Link
                    to="/data-optimization"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Data Optimization
                  </Link>
                  <Link
                    to="/tempChat"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Chat
                  </Link>
                </div>
              )}
            </div>

            <Link to="/blog" onClick={() => setIsMobileMenuOpen(false)}>
              Blog
            </Link>

            {/* Mobile "Login" toggles login mode, same /auth route */}
            <span
              onClick={() => {
                handleLogin();
                setIsMobileMenuOpen(false);
              }}
            >
              Login
            </span>

            {/* Mobile "Get Started" toggles sign-up mode, same /auth route */}
            <div className="mobile-get-started">
              <span
                onClick={() => {
                  handleGetStarted();
                  setIsMobileMenuOpen(false);
                }}
              >
                Get Started
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;