// ModifiedSection.js
import React from "react";
import PyramidStory from "./Pyramid";

const ModifiedSection = () => {
  return (
    <div style={{ width: "100%", backgroundColor: "#F9FAFB" }}>
      <PyramidStory />
    </div>
  );
};

export default ModifiedSection;
