import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // <-- Import Link from react-router-dom
import { ArrowRight } from 'lucide-react';
import './CaseStudyPreview.css';
import questionMarkImage from '../../../assets/questionMark.jpg';
import Legal from '../../../assets/legal.jpg';
import Marketing from '../../../assets/marketing.jpg';

const caseStudies = [
  {
    slug: 'precision-medicine-intelligence-platform',
    title: 'Revolutionizing Healthcare Through AI-Powered Pharmacogenomics',
    image: questionMarkImage,
    description: 'Discover how our AI-powered platform transforms personalized medicine and optimizes drug prescription through pharmacogenomics.',
  },
  {
    slug: 'knowledge-nexus-document-intelligence-system-for-legal-professionals',
    title: 'Transforming Legal Analysis with AI-Powered Contradiction Detection',
    image: Legal,
    description: 'Learn how our advanced AI solution helps uncover contradictions in legal documents, streamlining litigation preparation and enhancing case strategy for high-stakes lawsuits.',
  },
  {
    slug: 'marketing-survey-insights-action-platform',
    title: 'Empowering Content Marketing with AI',
    image: Marketing,
    description: 'A dynamic marketing firm specializing in creating and managing personalized social media strategies for diverse clients, with a focus on podcasts and blogs as key content sources.',
  }
];

const CaseStudyPreview = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <section className="case-studies-section">
      <div className="case-studies-top-bar">
        <div className="hero-section-caseStudies">
          <div className="hero-content-caseStudies">
            <h2 className="hero-title-caseStudies">Selected Case Studies</h2>
            <p className="hero-description-caseStudies">
              Explore how we've helped leading organizations transform their operations through cutting-edge AI solutions.
            </p>
          </div>
        </div>
      </div>

      <div className="content-wrapper">
        <div className="case-studies-grid">
          {caseStudies.map((study, index) => (
            // Wrap each card in a Link that navigates to "/blog/<slug>"
            <Link
              key={study.slug}
              to={`/blog/${study.slug}`}
              className={`case-study-card ${hoveredIndex === index ? 'hovered' : ''}`}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div className="image-container">
                <div className="image-overlay" />
                <img
                  src={study.image}
                  alt={study.title}
                  className="study-image"
                />
              </div>

              <div className="card-content">
                <div className="action-badge">
                  <ArrowRight className="badge-icon" />
                </div>

                <h3 className="card-title">{study.title}</h3>
                <p className="card-description">{study.description}</p>

                <div className="read-more">
                  Read Case Study
                  <ArrowRight className="read-more-icon" />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CaseStudyPreview;
