import './BenefitsSection.css';
import React from 'react';
import Helix from '../../../assets/helix_white.png';

const BenefitsSection = () => {
  const benefits = [
    {
      icon: "fas fa-chalkboard-teacher",
      title: "AI & Tech Consulting",
      description: "From strategy to safety, our hands-on experts guide you in understanding and integrating AI to solve real business challenges."
    },
    {
      icon: "fas fa-laptop-code",
      title: "Custom Software & AI Development",
      description: "We build bespoke AI tools—automating workflows, collecting and analyzing data, and creating advanced ML models tailored to your needs."
    },
    {
      icon: "fas fa-users-cog",
      title: "Training & Enablement",
      description: "Empower your team with the knowledge and skills to leverage AI confidently, ensuring safe, responsible, and effective adoption."
    }
  ];

  return (
    <div className="benefits-wrapper">
      <div className="transparent-bg">
        <div className="benefits-purple-continue">
          <img src={Helix} alt="AI Solutions" className="helix-simple" />
        </div>
        <section className="benefits-section">
          <div className="benefits-container">
            <div className="benefits-header">
              <h3>Helix Labs Vision</h3>
              <h2>Building the Future of Applied AI</h2>
              <p>We combine practical engineering with innovative research, helping you harness AI for real-world impact.</p>
            </div>
            <div className="benefits-grid">
              {benefits.map((benefit, index) => (
                <div key={index} className="benefit-item">
                  <i className={`${benefit.icon} benefit-icon`}></i>
                  <h3>{benefit.title}</h3>
                  <p>{benefit.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BenefitsSection;
