import React, { useRef } from 'react';
import NetworkBackground from '../../NetworkBackground';
import './HeroSection.css';
// 1) Import your DNA_PNG
import DNA_PNG from '../../../assets/DNA_PNG_LONG.png'; // <-- adjust the path as needed

const HeroSection = () => {
  const containerRef = useRef(null);

  return (
    <div className="hero-container" ref={containerRef}>
      <NetworkBackground theme="neural" containerRef={containerRef} />
      <div className="video-overlay"></div>

      <div className="hero-content">
        <div className="hero-content-box">
          <div className="hero-content-box-left">
            <div className="overlay-text">
              <h1>
                AI Training, Education, and Development<br />
                By Builders, Not Business Consultants
              </h1>
              <h2>
                We don't just guide - we build and utilize artificial intelligence every day,
                bringing hands-on expertise to transform your business.
              </h2>

              {/* 2) Insert the DNA_PNG here */}
              <img src={DNA_PNG} alt="DNA graphic" className="dna-image" />
            </div>

            <div className="stats-container">
              <div className="stat-item">
                <span className="stat-number">Development</span>
                <span className="stat-label">Custom Solutions</span>
              </div>

              <div className="stat-item">
                <span className="stat-number">Consulting</span>
                <span className="stat-label">Expert Guidance</span>
              </div>

              <div className="stat-item">
                <span className="stat-number">Technology</span>
                <span className="stat-label">Advanced Tools</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ending-gradient">

      </div>
    </div>
  );
};

export default HeroSection;
