import React from 'react';
import './tempChat.css';
import Footer from '../../../Components/Footer/Footer';

const TempChat = () => {
  return (
    <div className="temp-chat-page">
      <div className="temp-chat-construction-container">
        <div className="temp-chat-construction-content">
          <h1 className="temp-chat-heading">Helix chat is currently under construction!</h1>
          <p className="temp-chat-paragraph">Check back soon for updates!</p>
          <div className="temp-chat-user-link">
            <p className="temp-chat-paragraph">If you are a current chat page user, <a className="temp-chat-link" href="/chat">click here</a></p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TempChat;