import React, { useState, useRef, useEffect } from 'react';
import { Brain, Shield, BookOpen, Code, Zap, PieChart, Users, CheckCircle, ArrowRight, X } from 'lucide-react';
import './AIConsulting.css';
import Footer from '../../../Components/Footer/Footer';
import axios from 'axios'
// Keeping the NetworkBackground component as requested but with unique name
const ConsultingNetworkBackground = ({ theme = 'neural' }) => {
  // NetworkBackground implementation remains unchanged
  const canvasRef = useRef(null);
  const nodesRef = useRef([]);
  const requestIdRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let width = canvas.width = window.innerWidth;
    let height = canvas.height = window.innerHeight;

    const initNodes = () => {
      const nodeCount = Math.floor((width * height) / 80000);
      nodesRef.current = Array.from({ length: nodeCount }, () => ({
        x: Math.random() * width,
        y: Math.random() * height,
        vx: (Math.random() - 0.5) * 0.2,
        vy: (Math.random() - 0.5) * 0.2,
        radius: Math.random() * 3 + 2
      }));
    };

    const animate = () => {
      ctx.clearRect(0, 0, width, height);

      // Update and draw nodes
      nodesRef.current.forEach(node => {
        // Update position
        node.x += node.vx;
        node.y += node.vy;

        // Bounce off edges
        if (node.x < 0 || node.x > width) node.vx *= -1;
        if (node.y < 0 || node.y > height) node.vy *= -1;

        // Draw node
        ctx.beginPath();
        ctx.fillStyle = theme === 'neural'
          ? 'rgba(21, 110, 18, 0.8)'      // Using the brand green color
          : 'rgba(21, 110, 18, 0.9)';
        ctx.arc(node.x, node.y, node.radius, 0, Math.PI * 2);
        ctx.fill();
      });

      // Draw connecting lines between nodes
      const distanceThreshold = 100;
      for (let i = 0; i < nodesRef.current.length; i++) {
        for (let j = i + 1; j < nodesRef.current.length; j++) {
          const nodeA = nodesRef.current[i];
          const nodeB = nodesRef.current[j];
          const dx = nodeA.x - nodeB.x;
          const dy = nodeA.y - nodeB.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          if (distance < distanceThreshold) {
            const opacity = (1 - distance / distanceThreshold) * 0.6;
            ctx.beginPath();
            ctx.strokeStyle = theme === 'neural'
              ? `rgba(21, 110, 18, ${opacity})`
              : `rgba(21, 110, 18, ${opacity})`;
            ctx.lineWidth = 1;
            ctx.moveTo(nodeA.x, nodeA.y);
            ctx.lineTo(nodeB.x, nodeB.y);
            ctx.stroke();
          }
        }
      }

      requestIdRef.current = requestAnimationFrame(animate);
    };

    const handleResize = () => {
      width = canvas.width = window.innerWidth;
      height = canvas.height = window.innerHeight;
      initNodes();
    };

    window.addEventListener('resize', handleResize);
    initNodes();
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      if (requestIdRef.current) {
        cancelAnimationFrame(requestIdRef.current);
      }
    };
  }, [theme]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: .4,
        zIndex: 2, // Changed from -1 to 2 so it's more visible but still behind content
        pointerEvents: 'none'
      }}
    />
  );
};

// Form Notification Component - Replaces the previous ConsultingSuccessMessage
const FormNotification = ({ type, message, onClose }) => (
  <div className={`consulting-form-notification ${type}-notification`}>
    <div className="consulting-notification-icon">
      {type === 'success' ? <CheckCircle size={20} /> : <X size={20} />}
    </div>
    <div className="consulting-notification-message">{message}</div>
    <button
      type="button"
      className="consulting-notification-close"
      onClick={onClose}
    >
      ×
    </button>
  </div>
);

const AIConsulting = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  // Updated form state management
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null); // null, 'success', or 'error'
  const [statusMessage, setStatusMessage] = useState('');

  const [activeConsultingTab, setActiveConsultingTab] = useState('strategy');

  // Refs for scroll animations
  const consultingSectionsRef = useRef([]);
  const [consultingTheme] = useState('neural');

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('consulting-animate-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    const sections = document.querySelectorAll('.consulting-animate-section');
    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

    // Clear error messages when user starts typing again
    if (submitStatus === 'error') {
      setSubmitStatus(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/consult`, formData);

      setSubmitStatus('success');
      setStatusMessage(response.data?.message || 'Your consultation request has been sent successfully. We\'ll be in touch within 24 hours!');
      setFormData({ name: '', email: '', message: '' });

      // Auto-dismiss success message after 8 seconds
      setTimeout(() => {
        setSubmitStatus(null);
      }, 8000);
    } catch (error) {
      setSubmitStatus('error');
      setStatusMessage(error.response?.data?.message || 'An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const closeNotification = () => {
    setSubmitStatus(null);
  };

  const scrollToContact = () => {
    document.getElementById('consulting-contact').scrollIntoView({ behavior: 'smooth' });
  };

  // Services content organized by tabs
  const consultingServicesContent = {
    // Services content remains unchanged
    education: {
      title: "AI Education & Literacy",
      icon: BookOpen,
      description: "We provide accessible AI education for individuals and teams at all technical levels.",
      benefits: [
        "Customized training for technical and non-technical staff",
        "Hands-on workshops with practical examples",
        "Demystifying AI concepts for everyday business use",
        "Staying current with AI trends and capabilities"
      ],
      details: "Our AI education programs are designed to make artificial intelligence accessible to everyone in your organization. We believe AI literacy is essential for all staff members, not just technical teams. Our workshops break down complex concepts into practical, understandable modules tailored to different knowledge levels. From executive overviews to hands-on technical training, we help your team understand how AI works, its limitations, and how to effectively leverage it in their daily work. These education services can be standalone or part of a broader AI implementation."
    },
    strategy: {
      title: "AI Strategy Development",
      icon: PieChart,
      description: "We craft AI strategies tailored to your specific business needs, whether simple or complex.",
      benefits: [
        "Right-sized AI roadmaps aligned with your business objectives",
        "Technology recommendations matched to your use cases",
        "Prioritized implementation plan with clear ROI metrics",
        "Separating AI hype from practical applications"
      ],
      details: "Our strategic approach begins with understanding your specific needs - not every business requires enterprise-scale AI. We help identify opportunities where AI can add real value, whether it's simple automation of repetitive tasks or more complex applications. Unlike consultants who rely solely on theoretical knowledge, our strategies are informed by hands-on experience implementing AI solutions across multiple industries and scales. We help you navigate the AI landscape, separate hype from reality, and develop practical pathways to AI adoption that deliver tangible outcomes."
    },
    implementation: {
      title: "AI Implementation & Training",
      icon: Code,
      description: "We guide you through AI implementation at any scale, from simple tools to complex systems.",
      benefits: [
        "Expert guidance for both simple and complex implementations",
        "Practical training focused on your specific use cases",
        "Integration with existing systems and workflows",
        "Ongoing support and knowledge transfer"
      ],
      details: "Implementation is where many AI initiatives fail, but our practical expertise ensures successful adoption at any scale. Whether you're looking to implement basic AI tools for efficiency or develop custom solutions, we provide right-sized implementation support. Our approach focuses on practical applications and real-world scenarios relevant to your specific needs. We emphasize knowledge transfer, ensuring your team gains the skills to leverage AI effectively and independently after our engagement concludes."
    },
    governance: {
      title: "AI Ethics & Governance",
      icon: Shield,
      description: "We ensure your AI solutions are deployed responsibly, ethically, and in compliance with regulations.",
      benefits: [
        "Ethical AI framework development",
        "Bias detection and mitigation strategies",
        "Regulatory compliance guidance",
        "Responsible AI practices for organizations of all sizes"
      ],
      details: "The ethical and responsible use of AI is critical regardless of organization size or implementation scale. We help you establish appropriate governance practices that address ethical considerations, regulatory compliance, and potential biases in AI systems. Our approach ensures transparency, fairness, and accountability in your AI applications. We make ethics and governance accessible and practical, providing guidance that protects your organization while maximizing the benefits of AI adoption."
    }
  };

  return (
    <div>
      <div className="consulting-container">
        <ConsultingNetworkBackground />

        {/* Hero Section with Parallax Effect */}
        <section className="consulting-hero-section">
          <div className="consulting-hero-content">
            <div className="consulting-hero-text">
              <h1>Transform Your Business</h1>
              <h2>with AI Expertise That Delivers Real Results</h2>
              <p className="consulting-hero-subtitle">We make AI accessible through education, training, and practical implementation - helping organizations of all sizes understand and leverage AI effectively.</p>
              <button onClick={scrollToContact} className="consulting-cta-button">
                Begin Your AI Journey
                <ArrowRight className="consulting-arrow-icon" />
              </button>
            </div>
          </div>
        </section>

        {/* Our Advantage Section - Why Choose Us */}
        <section className="consulting-advantage-section consulting-animate-section">
          <div className="consulting-inner-container">
            <div className="consulting-section-header">
              <span className="consulting-overline">OUR ADVANTAGE</span>
              <h2>Why Our AI Consulting Is Different</h2>
              <p className="consulting-section-subtitle">We're not just consultants. We're AI practitioners who build and implement real solutions every day.</p>
            </div>

            <div className="consulting-advantage-grid">
              <div className="consulting-advantage-card">
                <div className="consulting-advantage-icon">
                  <Brain />
                </div>
                <div className="consulting-advantage-content">
                  <h3>Practical AI Experience</h3>
                  <p>Our foundation is built on actively using and developing AI solutions every day. We don't just consult—we build, test, and implement AI technologies in real-world scenarios.</p>
                </div>
              </div>

              <div className="consulting-advantage-card">
                <div className="consulting-advantage-icon">
                  <Users />
                </div>
                <div className="consulting-advantage-content">
                  <h3>Industry-Specific Expertise</h3>
                  <p>We've successfully implemented AI solutions across healthcare, marketing, engineering, law, and more, giving us insights into the unique challenges of various industries.</p>
                </div>
              </div>

              <div className="consulting-advantage-card">
                <div className="consulting-advantage-icon">
                  <CheckCircle />
                </div>
                <div className="consulting-advantage-content">
                  <h3>Results-Driven Approach</h3>
                  <p>We focus on practical applications that deliver measurable business outcomes, not theoretical use cases or trendy AI buzzwords.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Services Section with Tabs */}
        <section className="consulting-services-section consulting-animate-section">
          <div className="consulting-inner-container">
            <div className="consulting-section-header">
              <span className="consulting-overline">OUR SERVICES</span>
              <h2>Comprehensive AI Consulting Services</h2>
              <p className="consulting-section-subtitle">From strategy to implementation, we provide end-to-end support for your AI journey.</p>
            </div>

            <div className="consulting-services-tabs">
              <div className="consulting-tabs-navigation">
                {Object.keys(consultingServicesContent).map((tab) => (
                  <button
                    key={tab}
                    className={`consulting-tab-button ${activeConsultingTab === tab ? 'consulting-active' : ''}`}
                    onClick={() => setActiveConsultingTab(tab)}
                  >
                    {(() => {
                      const IconComponent = consultingServicesContent[tab].icon;
                      return <IconComponent className="consulting-tab-icon" />;
                    })()}
                    <span>{consultingServicesContent[tab].title}</span>
                  </button>
                ))}
              </div>

              <div className="consulting-tab-content">
                <div className="consulting-tab-header">
                  {(() => {
                  const IconComponent = consultingServicesContent[activeConsultingTab].icon;
                  return <IconComponent className="consulting-tab-content-icon" />;
                })()}
                  <h3>{consultingServicesContent[activeConsultingTab].title}</h3>
                </div>
                <p className="consulting-tab-description">{consultingServicesContent[activeConsultingTab].description}</p>
                <div className="consulting-tab-details">{consultingServicesContent[activeConsultingTab].details}</div>

                <div className="consulting-tab-benefits">
                  <h4>Key Benefits:</h4>
                  <ul>
                    {consultingServicesContent[activeConsultingTab].benefits.map((benefit, index) => (
                      <li key={`benefit-${activeConsultingTab}-${index}`}><CheckCircle className="consulting-benefit-icon" />{benefit}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Our Approach - Visual Timeline */}
        <section className="consulting-approach-section consulting-animate-section">
          <div className="consulting-inner-container">
            <div className="consulting-section-header">
              <span className="consulting-overline">AI EDUCATION</span>
              <h2>Learn, Apply, and Excel with AI</h2>
              <p className="consulting-section-subtitle">From basic understanding to advanced implementation, we guide you at every step</p>
            </div>

            <div className="consulting-timeline">
              {/* Timeline items remain unchanged */}
              <div className="consulting-timeline-item">
                <div className="consulting-timeline-number">1</div>
                <div className="consulting-timeline-content">
                  <h3>AI Literacy Fundamentals</h3>
                  <p>We begin with essential AI education, helping your team understand key concepts, capabilities, and limitations. Our workshops make complex AI topics accessible to everyone regardless of technical background.</p>
                  <ul className="consulting-timeline-features">
                    <li>AI terminology and concepts</li>
                    <li>Current capabilities & limitations</li>
                    <li>Distinguishing hype from reality</li>
                    <li>Hands-on experience with basic tools</li>
                  </ul>
                </div>
              </div>

              <div className="consulting-timeline-item">
                <div className="consulting-timeline-number">2</div>
                <div className="consulting-timeline-content">
                  <h3>Use Case Discovery</h3>
                  <p>We help you identify practical applications of AI for your specific needs, focusing on high-value, achievable goals that align with your organizational capabilities and objectives.</p>
                  <ul className="consulting-timeline-features">
                    <li>Opportunity identification</li>
                    <li>Value assessment</li>
                    <li>Complexity evaluation</li>
                    <li>Resource planning</li>
                  </ul>
                </div>
              </div>

              <div className="consulting-timeline-item">
                <div className="consulting-timeline-number">3</div>
                <div className="consulting-timeline-content">
                  <h3>Hands-On Skills Building</h3>
                  <p>Through practical workshops, we build your team's ability to work with AI tools and platforms, tailoring the training to your specific needs and technical capabilities.</p>
                  <ul className="consulting-timeline-features">
                    <li>Tool-specific training</li>
                    <li>Prompt engineering techniques</li>
                    <li>Data preparation methods</li>
                    <li>Integration with workflows</li>
                  </ul>
                </div>
              </div>

              <div className="consulting-timeline-item">
                <div className="consulting-timeline-number">4</div>
                <div className="consulting-timeline-content">
                  <h3>Implementation Support</h3>
                  <p>We provide guidance and support as you apply your new AI skills to real business challenges, helping you overcome obstacles and adapt techniques to your specific context.</p>
                  <ul className="consulting-timeline-features">
                    <li>Supervised practice</li>
                    <li>Practical application support</li>
                    <li>Problem-solving assistance</li>
                    <li>Process integration</li>
                  </ul>
                </div>
              </div>

              <div className="consulting-timeline-item">
                <div className="consulting-timeline-number">5</div>
                <div className="consulting-timeline-content">
                  <h3>Responsible AI Practices</h3>
                  <p>We help you establish ethical guidelines and best practices for AI use within your organization, ensuring compliance and building trust with your stakeholders.</p>
                  <ul className="consulting-timeline-features">
                    <li>Ethics framework development</li>
                    <li>Privacy protection practices</li>
                    <li>Bias identification techniques</li>
                    <li>Transparency guidelines</li>
                  </ul>
                </div>
              </div>

              <div className="consulting-timeline-item">
                <div className="consulting-timeline-number">6</div>
                <div className="consulting-timeline-content">
                  <h3>Long-term AI Capability Building</h3>
                  <p>We help establish ongoing learning and development paths to continuously enhance your team's AI capabilities as the technology evolves.</p>
                  <ul className="consulting-timeline-features">
                    <li>Learning path development</li>
                    <li>Advanced topic workshops</li>
                    <li>Trend monitoring</li>
                    <li>Capability assessment</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Contact section - Updated with improved form */}
        <section id="consulting-contact" className="consulting-contact-section consulting-animate-section">
          <div className="consulting-inner-container">
            <div className="consulting-section-header">
              <span className="consulting-overline">GET STARTED</span>
              <h2>Begin Your AI Transformation</h2>
              <p className="consulting-section-subtitle">Ready to harness the power of AI for your business? Our team of experts is here to guide you through every step of the journey.</p>
            </div>

            <div className="consulting-contact-card">
              <div className="consulting-contact-card-inner">
                <div className="consulting-contact-info">
                  <h3>Let's Work Together</h3>
                  <p>Schedule a free consultation to discuss how we can help your organization leverage AI effectively.</p>

                  <div className="consulting-contact-features">
                    <div className="consulting-contact-feature">
                      <CheckCircle className="consulting-feature-icon" />
                      <p>Customized AI strategy tailored to your business needs</p>
                    </div>
                    <div className="consulting-contact-feature">
                      <CheckCircle className="consulting-feature-icon" />
                      <p>Practical implementation support from experienced AI practitioners</p>
                    </div>
                    <div className="consulting-contact-feature">
                      <CheckCircle className="consulting-feature-icon" />
                      <p>Comprehensive training and knowledge transfer</p>
                    </div>
                    <div className="consulting-contact-feature">
                      <CheckCircle className="consulting-feature-icon" />
                      <p>Ongoing optimization to ensure long-term success</p>
                    </div>
                  </div>
                </div>

                <div className="consulting-contact-form-container">
                  <form onSubmit={handleSubmit} className="consulting-contact-form">
                    <div className="consulting-form-header">
                      <h3>Schedule a Consultation</h3>
                      <p>Fill out the form below and we'll get back to you within 24 hours.</p>
                    </div>

                    {/* Notification component */}
                    {submitStatus && (
                      <FormNotification
                        type={submitStatus}
                        message={statusMessage}
                        onClose={closeNotification}
                      />
                    )}

                    <div className="consulting-form-row">
                      <div className="consulting-form-group">
                        <label htmlFor="name">Your Name</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="John Smith"
                          required
                          disabled={isSubmitting}
                        />
                      </div>

                      <div className="consulting-form-group">
                        <label htmlFor="email">Your Email</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="john@example.com"
                          required
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>

                    <div className="consulting-form-group">
                      <label htmlFor="message">Tell us about your AI consultation needs</label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Describe your organization and what you hope to achieve with AI..."
                        required
                        disabled={isSubmitting}
                      />
                    </div>

                    <button
                      type="submit"
                      className={`consulting-submit-button ${isSubmitting ? 'submitting' : ''}`}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Sending...' : 'Schedule Consultation'}
                      <ArrowRight className="consulting-button-icon" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer/>
    </div>
  );
};

export default AIConsulting;