import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import './ModifiedSection.css';

const ModifiedSectionDevelopment = () => {
  const navigate = useNavigate();
  const [activePhrase, setActivePhrase] = useState(0);
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const aiPhrases = ["Revolutionize", "Transform", "Optimize", "Innovate", "Empower"];

  useEffect(() => {
    const interval = setInterval(() => {
      setActivePhrase((prev) => (prev + 1) % aiPhrases.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  // Handle navigation to solutions page
  const handleSolutionsClick = () => {
    navigate("/custom-ai-solutions");
    window.scrollTo(0, 0); // Scroll to top of page
  };

  return (
    <div className={`section-container ${inView ? 'in-view' : ''}`} ref={ref}>
      <div className="separator-line"></div>
      <div className="main-content">
        <div className="right-container">
          <div className="tagline-wrapper">
            <h2>We help you</h2>
            <div className="phrase-container">
              {aiPhrases.map((phrase, index) => (
                <span key={index} className={index === activePhrase ? 'phrase active' : 'phrase'} >
                  {phrase}
                </span>
              ))}
            </div>
            <h2>with AI</h2>
          </div>
        </div>
        <div className="left-container">
          <div className="content-wrapper">
            <div className="title-tag-consulting">Custom Software & AI Development</div>
            <div className="consulting-points">
              <div className="point">
                <h3 className="point-title">Tailored Solutions</h3>
                <p className="point-desc">We create custom AI tools that integrate seamlessly into your workflows, automating tasks and driving efficiency.</p>
              </div>
              <div className="point">
                <h3 className="point-title">Advanced Model Development & Fine-Tuning</h3>
                <p className="point-desc">We specialize in designing, training, and refining advanced machine learning models. From chatbots to advanced AI, we craft powerful models that solve real business challenges.</p>
              </div>
              <div className="point">
                <h3 className="point-title">End-to-End Support</h3>
                <p className="point-desc">From concept to deployment, we handle every stage of the process, ensuring your solutions are built to last.</p>
              </div>
            </div>
            <div className="button-container-consulting">
              <button className="solution-button" onClick={handleSolutionsClick}>See Solutions</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifiedSectionDevelopment;