import { BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Navbar, CustomAISolutions, AIConsulting, DataOptimization, ChatPage, ChatHistory, NotePage, Blog, BlogList, ManageAccount, NetworkGraph, ConstructionPage  } from './containers';
import { LandingPage, Contact, AuthPage, Pricing, Success, Cancel, PaymentForm, ProtectedRoute, Popup, About, ForgotPassword, PasswordReset, AnswerQuestion, PrivacyPolicy } from './Components';
import './App.css';
import ReactDOM from 'react-dom';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { AuthProvider, useAuth } from './Components/User_auth/AuthContext';
import { Provider } from 'react-redux'; // Import Provider
import store from './redux/store'; // Import your store


// Import your NetworkBackground component
import NetworkBackground from './Components/NetworkBackground.jsx';

const MainContent = () => { // main content is what app.js used to be, it contains the whole app
  //this tracks user to see if they route to chat section, if they do, 100% width navbar is not rendered
  const location = useLocation();
  const { isAuthenticated } = useAuth(); // useAuth hook to check authentication status

 let appClassName = 'App';
  if (location.pathname === '/') {
    appClassName += ' landing-gradient';
  }

  return (
    <AuthProvider>
      <Popup /> {/* Your Popup component */}
      {/* Place the NetworkBackground at the top level */}
      <div className={appClassName}>
        {!(location.pathname === '/chat' || location.pathname === '/chatPDF') && <Navbar />}
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/auth/forgotpassword" element={<ForgotPassword />} />
          <Route path="/auth/reset-password" element={<PasswordReset />} />
          <Route path="/notes" element={<NotePage />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/payment" element={<PaymentForm />} />
          <Route path="/blog/:blogId" element={<Blog />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/about" element={<About />} />
          <Route path="/answer" element={<AnswerQuestion />} />
          <Route path="/chat" element={<ChatPage />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
          <Route
            path="/manage-account"
            element={
              <ProtectedRoute>
                <ManageAccount />
              </ProtectedRoute>
            }
          />
          <Route path="/network" element={<NetworkGraph />} />
          <Route path="/custom-ai-solutions" element={<CustomAISolutions />} />
          <Route path="/ai-consulting" element={<AIConsulting />} />
          <Route path="/data-optimization" element={<DataOptimization />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/tempChat" element={<ConstructionPage />} />

        </Routes>
      </div>
    </AuthProvider>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <MainContent />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
