import React, { useState } from 'react';
import './DataOptimization.css';
import Footer from '../../../Components/Footer/Footer';
import axios from 'axios';

// Form Notification Component
const DataFormNotification = ({ type, message, onClose }) => (
  <div className={`data-form-notification ${type}-notification`}>
    <div className="data-notification-icon">
      {type === 'success' ? '✓' : '⚠'}
    </div>
    <div className="data-notification-message">{message}</div>
    <button
      type="button"
      className="data-notification-close"
      onClick={onClose}
    >
      ×
    </button>
  </div>
);

const DataOptimization = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  // Form state management
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null); // null, 'success', or 'error'
  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    // Clear error messages when user starts typing again
    if (submitStatus === 'error') {
      setSubmitStatus(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/contact`, formData);
      setSubmitStatus('success');
      setStatusMessage(response.data?.message || 'Your message has been sent successfully. We\'ll be in touch soon!');
      setFormData({ name: '', email: '', message: '' });

      // Auto-dismiss success message after 8 seconds
      setTimeout(() => {
        setSubmitStatus(null);
      }, 8000);
    } catch (error) {
      setSubmitStatus('error');
      setStatusMessage(error.response?.data?.message || 'An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const closeNotification = () => {
    setSubmitStatus(null);
  };

  return (
    <div className="data-optimization-page">
      {/* Data background effect */}
      <div className="data-bg"></div>

      <main>
        {/* Data Solutions Banner Section */}
        <section className="data-banner">
          <div className="data-banner-wrapper">
            <div className="data-banner-content">
              <h1>
                <span className="data-title-impact">Data Insights</span>
                <span className="data-title-focus">That Drive Growth</span>
              </h1>
              <div className="data-banner-divider"></div>
              <p className="data-banner-tagline">Transform your raw data into strategic business decisions and measurable results</p>
              <a href="#data-contact" className="data-cta-button">GET DATA INSIGHTS</a>
            </div>
            <div className="data-banner-graphic">
              <div className="data-chart-graphic">
                <div className="data-chart-bar"></div>
                <div className="data-chart-bar"></div>
                <div className="data-chart-bar"></div>
                <div className="data-chart-bar"></div>
                <div className="data-chart-bar"></div>
              </div>
            </div>
          </div>
        </section>

        {/* Our Data Approach Section */}
        <section className="data-approach-section">
          <div className="data-container">
            <div className="data-section-header">
              <h2>Why Choose Our Data Services</h2>
              <div className="data-underline"></div>
            </div>

            <div className="data-approach-wrapper">
              <div className="data-approach-stat-card">
                <div className="data-stat-card-inner">
                  <div className="data-stat-value">100%</div>
                  <p className="data-stat-description">of our team leverage data everyday - we have what it takes.</p>
                </div>
              </div>

              <div className="data-approach-features">
                <div className="data-approach-feature">
                  <div className="data-feature-icon">
                    <div className="data-icon-circle"></div>
                  </div>
                  <div className="data-feature-content">
                    <h3>Data-Driven Decision Making</h3>
                    <p>We transform raw data into strategic insights that drive measurable growth and ROI.</p>
                  </div>
                </div>

                <div className="data-approach-feature">
                  <div className="data-feature-icon">
                    <div className="data-icon-circle"></div>
                  </div>
                  <div className="data-feature-content">
                    <h3>Comprehensive Data Solutions</h3>
                    <p>From data collection to visualization, we provide end-to-end data services tailored to your business needs.</p>
                  </div>
                </div>

                <div className="data-approach-feature">
                  <div className="data-feature-icon">
                    <div className="data-icon-circle"></div>
                  </div>
                  <div className="data-feature-content">
                    <h3>Results-Focused Approach</h3>
                    <p>We focus on delivering actionable insights that translate directly into business value and growth.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Data Services Section */}
        <section className="data-services-section">
          <div className="data-container">
            <div className="data-section-header data-light">
              <h2>Our Data Services</h2>
              <div className="data-underline"></div>
              <p className="data-section-description">We turn overwhelming data into clear strategies, helping you make informed decisions and uncover new growth opportunities.</p>
            </div>

            <div className="data-services-container">
              <div className="data-service-column">
                <div className="data-service-item">
                  <div className="data-service-icon"></div>
                  <h3>Data Strategy Development</h3>
                  <p>Customized data roadmaps aligned with your business objectives, prioritizing initiatives for maximum ROI.</p>
                </div>

                <div className="data-service-item">
                  <div className="data-service-icon"></div>
                  <h3>Advanced Analytics</h3>
                  <p>Leverage predictive modeling and machine learning to uncover hidden patterns and forecast future trends.</p>
                </div>
              </div>

              <div className="data-service-column data-middle">
                <div className="data-service-item data-featured">
                  <div className="data-service-icon data-large"></div>
                  <h3>Interactive Dashboards</h3>
                  <p>Intuitive, visually compelling dashboards that make complex data accessible and actionable for all stakeholders.</p>
                </div>
              </div>

              <div className="data-service-column">
                <div className="data-service-item">
                  <div className="data-service-icon"></div>
                  <h3>Data Cleansing & Preparation</h3>
                  <p>Transform messy, inconsistent data into clean, reliable information for accurate analysis.</p>
                </div>

                <div className="data-service-item">
                  <div className="data-service-icon"></div>
                  <h3>Data Visualization</h3>
                  <p>Transform complex datasets into clear, compelling visuals that communicate insights effectively.</p>
                </div>
              </div>
            </div>

            <div className="data-services-cta">
              <a href="#data-contact" className="data-secondary-button">Explore Our Data Solutions</a>
            </div>
          </div>
        </section>

        {/* Data Optimization Value Section */}
        <section className="data-value-section">
          <div className="data-container">
            <div className="data-section-header">
              <h2>Data Optimization Value</h2>
              <div className="data-underline"></div>
            </div>

            <div className="data-value-grid">
              <div className="data-value-card">
                <div className="data-value-icon"></div>
                <h3>Enhanced Decision Making</h3>
                <p>Make strategic decisions with confidence using real-time insights.</p>
              </div>

              <div className="data-value-card">
                <div className="data-value-icon"></div>
                <h3>Operational Efficiency</h3>
                <p>Identify and eliminate inefficiencies to reduce costs and streamline processes.</p>
              </div>

              <div className="data-value-card">
                <div className="data-value-icon"></div>
                <h3>Market Opportunity Identification</h3>
                <p>Discover untapped markets and opportunities before your competitors.</p>
              </div>

              <div className="data-value-card">
                <div className="data-value-icon"></div>
                <h3>Customer Experience Improvement</h3>
                <p>Understand customer behavior to deliver personalized experiences.</p>
              </div>

              <div className="data-value-card">
                <div className="data-value-icon"></div>
                <h3>Competitive Advantage</h3>
                <p>Leverage data as a strategic asset to outperform competitors.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Contact Section - Improved Form */}
        <section id="data-contact" className="data-contact-section">
          <div className="data-container">
            <div className="data-contact-flex">
              <div className="data-contact-content">
                <h2>Ready to Transform Your Business with Data?</h2>
                <p>Let's discuss how our data expertise can help unlock growth opportunities and drive measurable results for your organization.</p>
                <ul className="data-contact-benefits">
                  <li>Customized data strategies aligned with your business goals</li>
                  <li>End-to-end data solutions from collection to visualization</li>
                  <li>Expert guidance from experienced data professionals</li>
                  <li>Clear focus on ROI and actionable insights</li>
                </ul>
              </div>
              <div className="data-contact-form-container">
                <form className="data-contact-form" onSubmit={handleSubmit}>
                  {/* Notification component */}
                  {submitStatus && (
                    <DataFormNotification
                      type={submitStatus}
                      message={statusMessage}
                      onClose={closeNotification}
                    />
                  )}

                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Your Name"
                    required
                    disabled={isSubmitting}
                  />
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Your Email"
                    required
                    disabled={isSubmitting}
                  />
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Tell us about your data challenges"
                    required
                    disabled={isSubmitting}
                  ></textarea>
                  <button
                    type="submit"
                    className={`data-cta-button ${isSubmitting ? 'data-submitting' : ''}`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'SENDING...' : 'GET DATA INSIGHTS'}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default DataOptimization;