import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './login.css'; // Importing the stylesheet
import { useAuth } from './AuthContext';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth(); // Using the login function from context


  const { setIsAuthenticated, setToken } = useAuth();

  const navigation = useNavigate();


 const handleLogin = async () => {
  if (!email || !password) {
    alert("Both fields are required!");
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/logon`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password })
    });

    const data = await response.json();

    if (response.ok) {
       login(data.token); // Use login from AuthContext
        navigation('/');
    } else {
      // Handle the error. Maybe the credentials were incorrect.
      alert(data.error || 'Login failed');
    }

  } catch (error) {
    // Handle unexpected errors like network issues
    alert('An error occurred. Please try again.');
  }
};


  return (
    <div className="container">
      <h2>Log in</h2>
      <form className="form-login">
        <input
          className="input-login"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className = "forgot-password-container">
          {/* forgot password Paragraph */}
         <div className= "forgot-password">
             <Link to="/auth/forgotpassword">Forgot Password?</Link>
        </div>
        </div>
        <input
          className="input-login"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          className="button-login"
          type="button"
          onClick={handleLogin}>
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;