import React, { useRef } from 'react';
import './LandingPage.css';
import HeroSection from './HeroSection/HeroSection.jsx';
import FeaturesSection from './FeaturesSection/FeaturesSection.jsx';
import CallToAction from './CallToAction/CallToAction.jsx';
import EmailCaptureForm from './EmailCaptureForm/EmailCaptureForm.jsx';
import IconSection from './Icon/IconSection.jsx';
import CaseStudyPreview from './CaseStudies/CaseStudiesHomePage.jsx';
import ModifiedSection from './BulletSection/ModifiedSection.jsx';
import ModifiedSectionDevelopment from './BulletSection/ModifiedSectionDevelopment.jsx';

import BenefitsSection from './BenefitsOfAI/BenefitsSection.jsx';
import Footer from '../Footer/Footer.jsx';
import BusinessSizeSection from './BusinessSize/BusinessSize.jsx';
// import WhatWeDoPage from './WhatWeDo/WhatWeDoPage.jsx'; // Commented out WhatWeDo import

const LandingPage = () => {
  // If you are not using the scroll functionality anymore, you can comment out or remove these lines:
  // const whatWeDoRef = useRef(null);
  // const scrollToWhatWeDo = () => {
  //   if (whatWeDoRef.current) {
  //     whatWeDoRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  return (
    <div>
      <div className="landing-container">
        <HeroSection />
        <BenefitsSection />
        {/* If TopBulletSection uses the scroll function as a prop and you are not using it anymore, remove the prop */}
        {/* <TopBulletSection scrollToWhatWeDo={scrollToWhatWeDo} /> */}
        <ModifiedSection />
        <ModifiedSectionDevelopment/>


        {/* Commented out the WhatWeDo section */}
        {/*
        <div ref={whatWeDoRef}>
          <WhatWeDoPage />
        </div>
        */}

        <CaseStudyPreview />
        {/*<BusinessSizeSection />*/}
        <EmailCaptureForm />
        <FeaturesSection />
        <IconSection />
        {/*<CallToAction />*/}
      </div>

      <Footer />
    </div>
  );
};

export default LandingPage;
