import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Pyramid.css";

// Images
import guidanceImage from "../../../assets/Guidance_highlighted.png";
import trainingImage from "../../../assets/Training_highlighted.png";
import foundationImage from "../../../assets/Foundation_highlighted.png";

// Data array for each step (title, content, and imported image)
const layersData = [
  {
    title: "Real-World Experience",
    content:
      "Our foundation is built on actively using and developing AI solutions every day. We don't just consult—we build, test, and implement AI technologies in real-world scenarios, giving us practical insights that theoretical consultants can't match. Our team stays at the cutting edge by consistently developing and refining AI applications for various industries.",
    imageSrc: foundationImage
  },
  {
    title: "AI Training & Implementation",
    content:
      "We guide you step by step, helping individuals, teams, or organizations integrate AI effectively. Because of our hands-on foundation, we can educate on the best applications for AI, optimal integration strategies, and practical implementation techniques. Our training programs are customized to your technical expertise level and specific business needs.",
    imageSrc: trainingImage
  },
  {
    title: "Strategic & Ethical Guidance",
    content:
      "We ensure every AI solution is deployed ethically and sustainably, aligning with long-term goals. For organizations looking to adopt or who already have AI tools, our guidance ensures proper governance frameworks, ethical considerations, and sustainable practices are in place. We help you navigate regulatory compliance while maximizing the strategic value of your AI investments.",
    imageSrc: guidanceImage
  }
];

// How many milliseconds between automatic transitions
const AUTO_SWITCH_INTERVAL = 4000;

// How long to pause when a user manually selects (in ms)
const MANUAL_SWITCH_PAUSE = 8000;

const PyramidStory = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false); // if true, auto-rotation is paused
  const navigate = useNavigate();

  useEffect(() => {
    // If auto-rotation is paused, do nothing
    if (isPaused) return;

    // Otherwise, set up the auto-rotation
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % layersData.length);
    }, AUTO_SWITCH_INTERVAL);

    // Cleanup
    return () => clearInterval(interval);
  }, [isPaused]);

  const handleManualSwitch = (index) => {
    // Immediately switch to the clicked layer
    setActiveIndex(index);

    // Pause auto-rotation
    setIsPaused(true);

    // Resume auto-rotation after a timeout
    setTimeout(() => {
      setIsPaused(false);
    }, MANUAL_SWITCH_PAUSE);
  };

  const handleConsultingClick = () => {
    navigate("/ai-consulting");
    // Ensure the page scrolls to the top when navigating
    window.scrollTo(0, 0);
  };

 return (
    <div className="pyramid-story-container">
      {/* Dividing Line */}
      <div className="divider-line"></div>

      {/* Main Title */}
      <div className="main-title-bg">
        <h1 className="main-title">Consulting</h1>
        <p className="subtitle">Transform Your Business with AI Expertise That Delivers Real Results</p>
      </div>

      {/* Content Container */}
      <div className="pyramid-content-wrapper">
        {/* LEFT SIDE: Title, Description, Nav Buttons */}
        <div className="pyramid-left-panel">
          <div className="description-box">
            <h2>{layersData[activeIndex].title}</h2>
            <div className="accent-line"></div>
            <p>{layersData[activeIndex].content}</p>
          </div>

          <div className="nav-controls">
            <div className="nav-buttons">
              {layersData.map((_, i) => (
                <button
                  key={i}
                  className={activeIndex === i ? "active" : ""}
                  onClick={() => handleManualSwitch(i)}
                >
                  {i + 1}
                </button>
              ))}
            </div>

            <button
              className="consulting-button"
              onClick={handleConsultingClick}
            >
              Learn More About Our Consulting
            </button>
          </div>
        </div>

        {/* RIGHT SIDE: Only render the active image */}
        <div className="pyramid-right-panel">
          <div className="pyramid-layer">
            <img
              src={layersData[activeIndex].imageSrc}
              alt={layersData[activeIndex].title}
              className="pyramid-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PyramidStory;